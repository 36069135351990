header {
  height: 100vh;
}

.header__container {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.header__container h1 {
  font-family: "Playfair Display", serif;
}

.header__container span {
  color: var(--color-primary);
}

/* ============ Call To Action =========== */

.cta {
  margin: 1.2rem 0;
}

.cta .btn {
  width: 15rem;
  letter-spacing: 0.3rem;
}

.header__social {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 4rem;
  bottom: 2rem;
  gap: 0.8;
  align-items: center;
  font-size: 30px;
}

.header__social::after {
  content: "";
  width: 2px;
  height: 4rem;
  background-color: white;
}

.scroll__down {
  position: absolute;
  right: 4rem;
  bottom: 2rem;
  writing-mode: vertical-rl;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}

.scroll__down .icon {
  font-size: 2rem;
}
/*============= MEDIA QUERIES (MEDIUM DEVICES)  ===================*/
@media screen and (max-width: 1024px) {
  .header__social {
    left: 3px;
  }
  .scroll__down {
    right: 3px;
  }
}

/*============= MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  .header__social {
    left: 3px;
  }

  .cta .btn {
    width: 10rem;
  }

  .scroll__down {
    /* right: 3px; */
  }
}

contact__container {
  display: flex;
}

/*============ FORM ================= */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 55%;
  margin-bottom: 4rem;
}

form input,
form textarea {
  padding: 15px 20px;
  background: #2b2b2b;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px;
  resize: none;
  width: 100%;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES)  ===================*/

@media screen and (max-width: 1024px) {
  form {
    width: 80%;
  }
}

/*============= MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  form {
    width: 100%;
  }

  form input,
  form textarea {
    font-size: 0.9rem;
    padding: 15px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg-color: #1d1d1d;
  --bg-color-variant: rgb(79, 78, 77);

  --color-primary: #08fdd8;
  --color-light: #8d8d8d;
  --transition: all 600ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 95%;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

h1 {
  font-size: 5rem;
  line-height: 1.4;
}

section {
  margin-top: 6rem;
}

/* section > h2,
section > h5 {
} */

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
  font-size: 3.5rem;
}

section > h5 {
  color: var(--color-light);
  font-weight: 300;
  font-size: 1.2rem;
}

a {
  color: white;
  transition: var(--transition);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary);
}

.text__light {
  color: var(--color-light);
}

.btn {
  background: none;
  padding: 1rem;
  cursor: pointer;
}

.btn__primary {
  outline: none;
  color: var(--color-primary);
  border-color: var(--color-primary);
  font-size: 1.1rem;
}

.btn__primary:hover {
  background: var(--color-primary);
  transition: var(--transition);
  color: var(--bg-color);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES)  ===================*/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }

  h1 {
    font-size: 4rem;
    line-height: 1.4;
  }

  .btn__primary {
    font-size: 1.1rem;
  }

  section > h2 {
    font-size: 2.5rem;
  }

  section > h5 {
    font-size: 1rem;
  }
}

/*============= MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 1.4;
  }
  .btn__primary {
    font-size: 0.8rem;
  }

  section > h2 {
    font-size: 2rem;
  }

  section > h5 {
    font-size: 0.7rem;
  }
}

.skills__container {
  display: grid;
  /* width: 100%; */
  grid-template-columns: 1fr 1fr;
  box-shadow: 0 5px 5px 0px rgba(56, 56, 56, 0.5), 0 0 0 1px #1f1f1f;
  border-radius: 20px;
  gap: 2rem;
  /* margin-top: 2rem; */
}

.skills__container > div {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  /* align-items: center; */
}
.skills__container > div h3 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.skills__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.skills__frontend {
  border-right: 1px solid rgba(56, 56, 56, 0.5);
}

.skills__details {
  display: flex;
  font-size: 2rem;
  gap: 1rem;
}

.skills__details > div h4 {
  font-size: 1.4rem;
}

.skills__details small {
  font-size: 1.2rem;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES)  ===================*/
@media screen and (max-width: 1024px) {
  .skills__container {
    /* display: grid; */
    grid-template-columns: 1fr;
  }
  .skills__container > div h3 {
    font-size: 1.4rem;
  }

  .skills__frontend {
    border-bottom: 1px solid rgba(56, 56, 56, 0.5);
    border-right: 0px;
  }
}

/*============= MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  .skills__container {
    grid-template-columns: 1fr;
  }

  .skills__frontend {
    border-right: 0px;
    border-bottom: 1px solid rgba(56, 56, 56, 0.5);
  }

  .skills__details {
    display: flex;
    font-size: 1.2rem;
    gap: 0.6rem;
  }

  .skills__details > div h4 {
    font-size: 1rem;
  }

  .skills__details small {
    font-size: 0.6rem;
  }
}
